<template>
  <master-layout
    smallTitle="Invia"
    bigTitle="Conferma ordine"
    :noPadding="true"
  >
    <div class="page flex flex-column">
      <div class="header flex flex-column my-4">
        <div class="flex justify-content-between">
          <!-- <span class="text-14 mr-2">Ordine numero 3276</span> -->
          <span class="text-14 mr-2">
            {{
              new Date().toLocaleDateString("it", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })
            }}
          </span>
        </div>
        <span class="text-14 font-bold mr-2">
          {{ currentCart.customer.ragioneSociale }}
        </span>
      </div>
      <div class="body flex-grow-1">
        <div class="item-container">
          <div class="item" v-for="item in currentCart.items" :key="item.name">
            <span class="font-bold">{{ item.name }}</span>
            <span class="font-bold text-color-primary">{{
              item.price.toLocaleString("it", {
                style: "currency",
                currency: "EUR",
              })
            }}</span>
            <div class="counter">
              <span class="actual">{{ item.number }}</span>
            </div>
          </div>
        </div>
        <div class="last-in-body">
          <ion-icon
            class="text-32 text-color-primary"
            :icon="arrowForward"
          ></ion-icon>
          <span class="text-color-primary font-bold">Ordine da inviare</span>
        </div>
      </div>
      <div class="footer flex flex-column justify-content-center py-3">
        <!-- <button @click="emptyCart" class="p-d button-primary">Conferma</button> -->
        <span class="text-14 font-bold mr-2"
          >Condividi la conferma dell'ordine</span
        >
        <div class="bottoni-attivita w-full flex justify-content-around mt-4">
          <ShareNetwork
            network="whatsapp"
            :url="url"
            :title="`Ordine yoUBe - ${currentCart.customer.ragioneSociale}`"
            :description="`N item: ${
              currentCart.n_items
            }, Tot: ${currentCart.tot_price.toFixed(2)}`"
            class="whatsapp"
            @open="onOpen"
          >
            <div class="logo">
              <ion-icon :icon="logoWhatsapp"></ion-icon>
            </div>
          </ShareNetwork>
          <ShareNetwork
            network="telegram"
            :url="url"
            :title="`Ordine yoUBe - ${currentCart.customer.ragioneSociale}`"
            :description="`N item: ${currentCart.n_item}, Tot: ${currentCart.tot_price}`"
            class="whatsapp"
            @open="onOpen"
          >
            <div class="logo">
              <ion-icon :icon="send"></ion-icon>
            </div>
          </ShareNetwork>
          <ShareNetwork
            network="email"
            :url="url"
            :title="`Ordine yoUBe - ${currentCart.customer.ragioneSociale}`"
            :description="`N item: ${currentCart.n_item}, Tot: ${currentCart.tot_price}`"
            class="email"
            @open="onOpen"
          >
            <div class="logo">
              <ion-icon :icon="mail"></ion-icon>
            </div>
          </ShareNetwork>
        </div>
      </div>
    </div>
  </master-layout>
</template>

<script setup>
import { ref, onUnmounted } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { mail, logoWhatsapp, send, copy, arrowForward } from "ionicons/icons";
import { IonIcon } from "@ionic/vue";

const store = useStore();
const router = useRouter();
const route = useRoute();

const currentCart = store.getters["shoppingCart/getCurrentCart"];
const itemsToRemove = ref([]);
function emptyCart() {
  console.log("empty cart");
  currentCart.items.forEach((item) => {
    itemsToRemove.value.push(item);
  });

  itemsToRemove.value.forEach((item) => {
    removeItem(item);
  });

  router.push("/catalogo");
}

function removeItem(item) {
  store.dispatch("shoppingCart/removeItem", item);
}
const url = ref("");
function buildUrl() {
  url.value =
    "https://marketing.youbecosmetics.com/confirm-order/" +
    route.params.idOrder +
    "/00000";
}

buildUrl();

onUnmounted(() => {
  emptyCart();
});
</script>

<style lang="scss" scoped>
.page {
  min-height: calc(100vh - 220px);

  .header {
    padding: 1rem;
  }
}
.body {
  display: flex;
  flex-direction: column;
  background: var(--client-ground2-color);
  height: 100px;
  width: 100%;
  padding: 1rem 0;
}

.item-container {
  flex-grow: 1;
}
.item {
  width: 90%;
  margin: auto;
  font-size: 14px;
  display: grid;
  grid-template-columns: 8fr 3fr 1fr;
  align-items: center;
  margin-bottom: 1rem;
}

.footer {
  padding: 1rem;
}
.bottoni-attivita {
  ion-icon {
    border: 1px solid var(--ion-color-primary);
    border-radius: 50%;
    background: var(--ion-color-primary);
    color: #fff !important;
    padding: 0.6rem;
  }
}

.last-in-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
